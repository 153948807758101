import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import ScrollSection from '@components/ScrollSection'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading1, TextBody } from '@components/TextStyles'
import {
  StatsMain,
  StatsInner,
  StatsSingle,
  StatsLabel,
  StatsRow,
  StatsCountWrap,
  StatsIcon,
  StatsIconMobile,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Stat = ({ stat }) => {
  const tl = useRef()
  const wrap = useRef()
  const count = useRef()
  const currentStat = useRef({
    value: 0,
  })

  const [ref, inView] = useInView({
    rootMargin: `-15% 0px -30% 0px`,
    triggerOnce: true,
  })

  useEffect(() => {
    tl.current = gsap.timeline({
      paused: true,
      defaults: {
        duration: 2,
        ease: 'power2.inOut',
      },
    })

    tl.current.from(
      wrap.current,
      {
        xPercent: window.innerWidth > 768 ? -80 : 0,
        autoAlpha: 0,
      },
      0
    )

    tl.current.to(
      currentStat.current,
      {
        value: stat.stat,
        onUpdate: () => {
          count.current.innerHTML = Math.floor(currentStat.current.value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
      },
      0
    )
  }, [stat])

  useEffect(() => {
    if (!inView) return

    tl.current.play()
  }, [inView])

  return (
    <StatsSingle ref={ref} show={inView}>
      <StatsLabel>
        <AnimateSplitText>
          <TextBody>{stat.label}</TextBody>
        </AnimateSplitText>
        <StatsIconMobile>
          <AnimateSlideIn>
            <GatsbyImage image={stat.icon.gatsbyImageData} alt={stat.label} />
          </AnimateSlideIn>
        </StatsIconMobile>
      </StatsLabel>
      <StatsRow>
        <StatsIcon>
          <AnimateSlideIn offset={10}>
            <GatsbyImage image={stat.icon.gatsbyImageData} alt={stat.label} />
          </AnimateSlideIn>
        </StatsIcon>
        <StatsCountWrap ref={wrap}>
          <Heading1 as="span">
            <span ref={count}>0</span>
          </Heading1>
        </StatsCountWrap>
      </StatsRow>
    </StatsSingle>
  )
}

const Stats = ({ stats }) => {
  return (
    <ScrollSection>
      <StatsMain>
        <Container>
          <Spacer size={[20, 40]} />
          <StatsInner>
            {React.Children.toArray(
              stats.map((stat, index) => <Stat stat={stat} />)
            )}
          </StatsInner>
        </Container>
        <Spacer size={[80, 160]} />
      </StatsMain>
    </ScrollSection>
  )
}

Stats.propTypes = {
  stats: PropTypes.array,
}

export default Stats
