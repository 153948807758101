import React, { useRef } from 'react'
import * as THREE from 'three'
import { OrbitControls, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

function LoadedModel() {
  const { nodes } = useGLTF('/obd/device-new.glb')

  return (
    <group dispose={null}>
      <group rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
        <group scale={0.01}>
          <mesh
            geometry={nodes.Casing_1.geometry}
            material={
              new THREE.MeshStandardMaterial({
                color: 0xf6f7f9,
                roughness: 0.3,
                metalness: 0.2,
              })
            }
          />
          <mesh
            geometry={nodes.Casing_2.geometry}
            material={
              new THREE.MeshPhongMaterial({
                color: 0xf2f3f5,
              })
            }
          />
          <mesh
            geometry={nodes.Logo_Projector.geometry}
            material={new THREE.MeshBasicMaterial({ color: 0xffffff })}
            position={[-0.01631062, -19.44512367, -0.00774477]}
          />
          <mesh
            geometry={nodes.Pins.geometry}
            material={
              new THREE.MeshStandardMaterial({
                color: 0xeeeeee,
                roughness: 0,
                metalness: 1,
              })
            }
            position={[-0.00017828, 33.18966293, -0.38150677]}
          />
          <mesh
            geometry={nodes['Port_+_Front_Panel'].geometry}
            material={new THREE.MeshPhongMaterial({ color: 0xa9aaab })}
            position={[-0.00018973, 30.37401772, -0.24715485]}
          />
        </group>
      </group>
    </group>
  )
}

const Model = props => {
  const group = useRef()

  useFrame(() => {
    group.current.rotation.x -= 0.005
    group.current.rotation.z -= 0.009
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group
          position={[0, 0, 0]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          scale={1}
        >
          <LoadedModel />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/obd/device-new.glb')

Model.propTypes = {}

export default Model
