import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ScrollSection from '@components/ScrollSection'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Container from '@components/Container'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import Spacer from '@components/Spacer'
import {
  JoinMain,
  JoinText,
  JoinImage,
  JoinItems,
  JoinItem,
  JoinItemInner,
  JoinItemIcon,
  JoinInner,
  JoinClose,
} from './index.style'
import { Heading2, TextBody } from '@components/TextStyles'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import Button from '@components/Button'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichText from '@components/RichText'
import Hr from '@components/Hr'
import { colors } from '@styles/vars/colors.style'
import SimpleBar from 'simplebar-react'
import { useInView } from 'react-intersection-observer'
import { useStore, setHeader } from '@Store/'
import { graphql, useStaticQuery } from 'gatsby'

const JoinItemWrap = ({ item, delay }) => {
  return (
    <JoinItem>
      <JoinItemInner>
        <AnimateSlideIn delay={delay}>
          <JoinItemIcon>
            <GatsbyImage
              image={item.imageIcon.gatsbyImageData}
              alt={item.imageIcon.description}
              objectFit="contain"
              objectPosition={'center'}
              style={{ maxWidth: '90%', maxHeight: '90%' }}
            />
          </JoinItemIcon>
        </AnimateSlideIn>
        <RichText content={item.text} delay={delay} />
      </JoinItemInner>
      <Hr bg={colors.darkgrey} />
    </JoinItem>
  )
}

const Join = ({
  heading,
  price,
  salePrice,
  text,
  items,
  image,
  close,
  modal,
}) => {
  const [, dispatch] = useStore()

  const { contentfulGlobals } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        diagnosticsPaymentUrl
      }
    }
  `)

  const { diagnosticsPaymentUrl } = contentfulGlobals

  const [ref, inView] = useInView({
    rootMargin: '0px 0px -95% 0px',
    triggerOnce: false,
  })

  useEffect(() => {
    if (!modal) setHeader(dispatch, inView ? `light` : `dark`)
  }, [modal, dispatch, inView])

  return (
    <ScrollSection>
      <JoinMain modal={modal} ref={ref}>
        {modal && (
          <JoinClose onClick={close}>
            <AnimateSlideIn>
              <Button variant={'secondary'} size={'small'}>
                Close
              </Button>
            </AnimateSlideIn>
          </JoinClose>
        )}
        {!modal && <Spacer size={[105, 160]} />}
        <SimpleBar>
          <JoinInner modal={modal}>
            <Container>
              <Grid>
                <GridItem tabletL={6} tabletLStart={7} tabletLAlign={`center`}>
                  {image && (
                    <JoinImage modal={modal}>
                      <AnimateImage delay={0.5} size={`large`}>
                        <GatsbyImage
                          image={image.gatsbyImageData}
                          alt={image.description}
                          style={modal ? { height: '100%' } : {}}
                        />
                      </AnimateImage>
                    </JoinImage>
                  )}
                </GridItem>
                <GridItem
                  tabletL={modal ? 5 : 4}
                  tabletLAlign={modal ? `flex-end` : `center`}
                  tabletLStart={modal ? 1 : 2}
                  tabletLOrder={-1}
                  desk={4}
                  deskAlign={`center`}
                  deskStart={2}
                >
                  <JoinText modal={modal}>
                    <Heading2 as={`h2`}>
                      <AnimateSplitText type={`lines,chars`}>
                        {heading}
                      </AnimateSplitText>
                    </Heading2>
                    <Spacer size={[28, 35]} />
                    <TextBody>
                      <AnimateSplitText delay={animation.textDelay}>
                        {salePrice ? (
                          <>
                            <s>{price}</s> {salePrice}
                          </>
                        ) : (
                          price
                        )}
                      </AnimateSplitText>
                    </TextBody>
                    <Spacer size={[28, 35]} />
                    <TextBody>
                      <AnimateSplitText delay={animation.textDelay}>
                        {text}
                      </AnimateSplitText>
                    </TextBody>
                    <Spacer size={[32, 64]} />
                    <JoinItems>
                      {React.Children.toArray(
                        items.map((item, index) => {
                          return (
                            <JoinItemWrap
                              item={item}
                              delay={animation.textDelay}
                            />
                          )
                        })
                      )}
                    </JoinItems>
                    <Spacer size={[32, 64]} />
                    <AnimateSlideIn delay={animation.textDelay}>
                      <Button
                        type="externalLink"
                        href={diagnosticsPaymentUrl}
                        size={'small'}
                        label={'Order Now'}
                      >
                        Order Now
                      </Button>
                    </AnimateSlideIn>
                  </JoinText>
                </GridItem>
              </Grid>
            </Container>
          </JoinInner>
        </SimpleBar>
        {!modal && <Spacer size={[64, 160]} />}
      </JoinMain>
    </ScrollSection>
  )
}

Join.propTypes = {
  heading: PropTypes.string,
  price: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.array,
  image: PropTypes.object,
}

export default Join
