import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'
import { Heading1 } from '@styles/vars/textStyles.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { mq } from '@styles/vars/media-queries.style'
import { AnimateSlideInWrap } from '@components/animation/AnimateSlideIn/index.style'

export const StatsMain = styled.div`
  background-color: ${colors.orange};
  color: ${colors.light};
  overflow: hidden;
`

export const StatsInner = styled.div`
  position: relative;
`

export const StatsSingle = styled.div`
  ${clamp('padding-bottom', 24, 40)};
  ${clamp('padding-top', 64, 120)};
  position: relative;

  ${Heading1} {
    ${clamp('font-size', 96, 200)};
    line-height: 0.8;
  }

  &:before {
    background-color: ${colors.light};
    bottom: 0;
    content: '';
    height: 0.1rem;
    left: 0;
    opacity: ${props => (props.show ? 1 : 0)};
    position: absolute;
    right: 0;
    transform: scaleX(${props => (props.show ? 1 : 0.6)});
    transform-origin: 0% 0%;
    transition: opacity 1s ${easings.inOut.default},
      transform 1s ${easings.inOut.default};
    transition-delay: ${props => props.delay}s;
  }
`

export const StatsLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  ${clamp('margin-bottom', 16, 32)};
`

export const StatsRow = styled.div`
  display: flex;
  align-items: flex-end;
`

export const StatsIconMobile = styled.div`
  height: 18px;
  line-height: 0;

  img {
    height: 18px;
    width: auto;
    padding: 0 1px;
  }

  ${AnimateSlideInWrap},
  ${AnimateSlideInWrap} > span {
    line-height: 0;
  }

  ${mq.tabletP} {
    display: none;
  }
`

export const StatsIcon = styled.div`
  display: none;

  img {
    height: 20px;
    width: auto;
    padding: 0 1px;

    ${mq.desk} {
      height: 35px;
    }
  }

  ${mq.tabletP} {
    display: block;
  }
`

export const StatsCountWrap = styled.div`
  text-align: right;
  width: 100%;
`
