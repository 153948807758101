import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading1, TextBody } from '@styles/vars/textStyles.style'

export const HeroMain = styled.div`
  margin-bottom: 6rem;
  padding-bottom: 20rem;
`

export const HeroPinned = styled.div`
  ${Heading1} {
    color: #f3f3f3;
    ${clamp('font-size', 56, 190)};
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 40svh;
    transform: translateY(-50%);

    ${mq.tabletL} {
      top: 50%;
    }

    ${mq.contained} {
      font-size: 10vw;
    }
  }

  ${mq.tabletL} {
    align-items: center;
    display: flex;
    height: 100svh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const HeroModel = styled.div`
  aspect-ratio: 1 / 1;
  margin: 20vh 0 15vh;

  ${mq.tabletL} {
    margin: 0 0 5vh;
  }
`

export const HeroButton = styled.div`
  align-items: center;
  background: #f3f3f3;
  ${clamp('border-radius', 25, 33)};
  display: flex;
  justify-content: center;
  ${clamp('gap', 24, 32)};
  ${clamp('height', 50, 66)};
  ${clamp('padding-left', 24, 32)};
  padding-right: 8px;
`

export const HeroImages = styled.div`
  display: ${props => (props.desktop ? 'none' : 'block')};

  ${mq.tabletL} {
    display: ${props => (props.desktop ? 'block' : 'none')};
    padding-top: 22svh;
    ${clamp('padding-left', 24, 48)};
    ${clamp('padding-right', 24, 48)};

    &:last-child {
      padding-top: 11svh;
    }
  }
`

export const HeroImage = styled.div`
  img {
    ${clamp('border-radius', 5, 10)};
  }

  ${mq.tabletL} {
    margin: 0 auto;
    width: 90%;
  }

  ${mq.desk} {
    width: 85%;
  }

  ${mq.deskL} {
    width: 75%;
  }
`

export const HeroImageInner = styled.div`
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  ${clamp('border-radius', 5, 10)};
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`

export const HeroText = styled.div`
  background: linear-gradient(
    0deg,
    rgba(250, 250, 250, 1) 50%,
    rgba(250, 250, 250, 0) 100%
  );
  bottom: 0;
  left: 0;
  ${clamp('padding-left', 24, 48)};
  ${clamp('padding-right', 24, 48)};
  right: 0;
  padding-bottom: 2.4rem;
  padding-top: 10rem;
  position: fixed;
  text-align: center;
  z-index: 5;
  width: 100%;

  ${mq.tabletL} {
    background: none;
    bottom: 0;
    padding-top: 0;
    position: absolute;
  }

  ${mq.desk} {
    padding-bottom: 4.8rem;
  }

  ${TextBody} {
    left: 50%;
    max-width: 20em;
    position: relative;
    transform: translateX(-50%);
    width: 100%;
  }
`
