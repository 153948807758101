import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { Heading2, TextBody } from '@styles/vars/textStyles.style'
import { AnimateImagePosition } from '@components/animation/AnimateImage/index.style'
import { HrMain } from '@components/Hr/index.style'
import { ContainerMain } from '@components/Container/index.style'
import { GridMain } from '@components/Grid/index.style'
import { ButtonMain } from '@components/Button/index.style'

export const JoinMain = styled.div`
  background-color: ${colors.darkergrey};
  color: ${colors.light};

  ${props =>
    props.modal &&
    `
    height: 100svh;
    width: 100svw;
  `};
`

export const JoinClose = styled.div`
  ${clamp('left', 24, 48)};
  position: absolute;
  top: 2.4rem;
  z-index: 15;

  ${mq.desk} {
    ${clamp('left', 24, 48)}
    ${clamp('top', 24, 48)}
  }

  ${ButtonMain} {
    color: ${colors.light};
  }
`

export const JoinInner = styled.div`
  ${props =>
    props.modal &&
    `

    ${ContainerMain} > ${GridMain} {
      padding: 9.2rem 0 4rem;

      ${mq.tabletL} {
        height: 100svh;
        padding: 4.8rem 0;
      }
    }

  `}
`

export const JoinText = styled.div`
  padding-top: 3.2rem;

  ${mq.tabletL} {
    padding-bottom: 6.4rem;
    padding-top: 0;

    ${props => props.modal && `padding-bottom: 3.2rem;`}
  }

  ${Heading2} {
    ${clamp('max-width', 460, 560, breakpoints.desk, breakpoints.contained)}
  }

  ${TextBody} {
    color: ${colors.lightgrey};
    ${clamp('max-width', 460, 560, breakpoints.desk, breakpoints.contained)}
  }
`

export const JoinImage = styled.div`
  position: relative;

  ${AnimateImagePosition} {
    border-radius: 1.6rem;

    ${props =>
      props.modal &&
      `
        ${mq.tabletL} {
          height: calc(100vh - 9.2rem);
        }
      `}
  }

  ${mq.desk} {
    ${clamp('padding-left', 32, 64, breakpoints.desk, breakpoints.contained)}
  }
`

export const JoinItems = styled.ul`
  list-style-type: none;
`

export const JoinItem = styled.li`
  ${clamp('padding-bottom', 24, 34)}
  ${clamp('padding-top', 24, 34)}
  position: relative;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    ${HrMain} {
      display: none;
    }
  }
`

export const JoinItemInner = styled.span`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`

export const JoinItemIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  width: 4.8rem;
`
