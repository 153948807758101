import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import HowItWorks from '@components/Diagnostics/HowItWorks'
import Carousel from '@components/Carousel'
import Join from '@components/Diagnostics/Join'
import Footer from '@components/Footer'
import Stats from '@components/Diagnostics/Stats'
import Overview from '@components/Diagnostics/Overview'
import Details from '@components/Diagnostics/Details'
import Modal from '@components/Modal'
import Hero from '@components/Diagnostics/Hero'
import { colors } from '@styles/vars/colors.style'
import { useStore, hideDiagnosticsModal } from '@Store/'

const IndexPage = ({ data }) => {
  const [store, dispatch] = useStore()
  const { diagnosticsModalOpen } = store

  const [orderModalOpen, setOrderModalOpen] = useState(false)

  const {
    seoTitle,
    keywords,
    seoDescription: { seoDescription },
    seoImage,
    heroText,
    heroOrderCtaLabel,
    heroOrderCtaPrice,
    heroOrderCtaSalePrice,
    heroCardImagery,
    overviewHeading,
    overviewSections,
    howItWorksHeading,
    howItWorksText: { howItWorksText },
    carouselItems,
    stats,
    joinHeading,
    joinPrice,
    joinSalePrice,
    joinText: { joinText },
    joinItems,
    joinImage,
    detailsImage,
    features,
    specs,
    releaseNotes,
  } = data.contentfulDiagnostics

  useEffect(() => {
    if (diagnosticsModalOpen) {
      setOrderModalOpen(true)
      hideDiagnosticsModal(dispatch)
    }
  }, [diagnosticsModalOpen, dispatch])

  return (
    <div style={{ backgroundColor: colors.light }}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        keywords={keywords}
      />

      <Modal
        isOpen={orderModalOpen}
        setClosed={() => setOrderModalOpen(false)}
        onDismiss={() => setOrderModalOpen(false)}
        ariaLabel="Order Now"
      >
        <Join
          heading={joinHeading}
          price={joinPrice}
          salePrice={joinSalePrice}
          text={joinText}
          items={joinItems}
          image={joinImage}
          close={() => setOrderModalOpen(false)}
          modal
        />
      </Modal>
      <Hero
        text={heroText}
        images={heroCardImagery}
        label={heroOrderCtaLabel}
        price={heroOrderCtaPrice}
        salePrice={heroOrderCtaSalePrice}
        openOrder={() => setOrderModalOpen(true)}
      />
      <Overview heading={overviewHeading} sections={overviewSections} />
      <HowItWorks heading={howItWorksHeading} text={howItWorksText} />
      <Carousel items={carouselItems} />
      <Stats stats={stats} />
      <Join
        heading={joinHeading}
        price={joinPrice}
        salePrice={joinSalePrice}
        text={joinText}
        items={joinItems}
        image={joinImage}
      />
      <Details
        image={detailsImage}
        features={features}
        specs={specs}
        releaseNotes={releaseNotes}
        openOrder={() => setOrderModalOpen(true)}
      />
      <Footer />
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query DiagnosticsPage {
    contentfulDiagnostics {
      contentful_id
      seoTitle
      keywords
      seoDescription {
        seoDescription
      }
      seoImage {
        file {
          url
        }
      }
      heroText
      heroOrderCtaLabel
      heroOrderCtaPrice
      heroOrderCtaSalePrice
      heroCardImagery {
        gatsbyImageData
        description
      }
      overviewHeading
      overviewSections {
        title
        text
        image {
          gatsbyImageData
          description
        }
      }
      howItWorksHeading
      howItWorksText {
        howItWorksText
      }
      carouselItems {
        text
        title
        screen
      }
      stats {
        label
        stat
        icon {
          gatsbyImageData
          description
        }
      }
      joinHeading
      joinPrice
      joinSalePrice
      joinText {
        joinText
      }
      joinItems {
        title
        text {
          raw
          references {
            __typename
            ... on ContentfulHome {
              contentful_id
              slug
            }
            ... on ContentfulNewsRoom {
              contentful_id
              slug
            }
            ... on ContentfulTextPage {
              contentful_id
              slug
            }
            ... on ContentfulDiagnostics {
              contentful_id
              slug
            }
          }
        }
        imageIcon {
          gatsbyImageData
          description
        }
      }
      joinImage {
        gatsbyImageData
        description
      }
      detailsImage {
        gatsbyImageData
        description
      }
      features {
        ... on ContentfulBlockSnippet {
          contentful_id
          heading
          text
        }
        ... on ContentfulBlockRichSnippet {
          contentful_id
          title
          richText: text {
            raw
            references {
              __typename
              ... on ContentfulHome {
                contentful_id
                slug
              }
              ... on ContentfulNewsRoom {
                contentful_id
                slug
              }
              ... on ContentfulTextPage {
                contentful_id
                slug
              }
              ... on ContentfulDiagnostics {
                contentful_id
                slug
              }
            }
          }
        }
      }
      specs {
        ... on ContentfulBlockSnippet {
          contentful_id
          heading
          text
        }
        ... on ContentfulBlockRichSnippet {
          contentful_id
          title
          richText: text {
            raw
            references {
              __typename
              ... on ContentfulHome {
                contentful_id
                slug
              }
              ... on ContentfulNewsRoom {
                contentful_id
                slug
              }
              ... on ContentfulTextPage {
                contentful_id
                slug
              }
              ... on ContentfulDiagnostics {
                contentful_id
                slug
              }
            }
          }
        }
      }
      releaseNotes {
        text {
          raw
        }
        date(formatString: "MMM DD")
        version
      }
    }
  }
`
